<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="page-content-inner pt-lg-0 pr-lg-0 mt-4">
        <div class="section-header">
          <div class="section-header-left">
            <h3><i class="uil-calendar-alt"></i> {{ $t("general.Agent") }}</h3>
          </div>
        </div>

        <div class="uk-width-1-1" uk-grid>
          <div class="uk-width-expand">
            <div class="card">
              <div class="card-body text-left uk-grid">
                <div class="uk-width-1-2">

                  <div class="select small w-4">
                    <label>{{ $t("general.view") }}</label>
                    <multiple-select-input :model.sync="displayPeriodUom" :multiple="false" :required="false"
                      :searchable="false" :showLabels="false" :values="displayPeriods" :allowEmpty="false" />
                  </div>
                </div>
                <div class="uk-width-1-2">

                  <div class="select small w-4">
                    <label>{{ $t("general.type_of_education") }}</label>
                    <multiple-select-input :model.sync="contentType" :multiple="false" :required="false"
                      :searchable="false" :showLabels="false" :values="contentTypes" :allowEmpty="false"
                      @select="contentType = $event; onChange($event)" />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="uk-width-1-1">
            <!--- \\\\\\\Post-->
            <div class="uk-card uk-card-default rounded mb-3">
              <div class="uk-card-body">
                <default-loading v-if="calendar_loading"></default-loading>
                <template v-else>
                  <div class="calendar-parent calendar_h ">
                    <calendar-view :items="items" :show-date="showDate"
                      :time-format-options="{ hour: 'numeric', minute: '2-digit' }" :enable-drag-drop="false"
                      :disable-past="disablePast" :disable-future="disableFuture" :show-times="showTimes"
                      :date-classes="myDateClasses" :display-period-uom="displayPeriodUom.id"
                      :display-period-count="displayPeriodCount" :starting-day-of-week="startingDayOfWeek"
                      :class="themeClasses" :period-changed-callback="periodChanged"
                      :current-period-label="useTodayIcons ? 'icons' : ''" :displayWeekNumbers="displayWeekNumbers"
                      :enable-date-selection="true" :selection-start="selectionStart" :selection-end="selectionEnd"
                      @date-selection-start="setSelection" @date-selection="setSelection"
                      @date-selection-finish="finishSelection" @drop-on-date="onDrop" @click-date="onClickDay"
                      @click-item="onClickItem">
                      <calendar-view-header slot="header" slot-scope="{ headerProps }" :header-props="headerProps"
                        @input="setShowDate" />
                    </calendar-view>
                  </div>
                </template>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  CalendarView,
  CalendarViewHeader,
  CalendarMathMixin,
} from "vue-simple-calendar";



import moment from "moment";
import user_module, { MODULE_NAME, GET_USER, CURRENT_ITEM, LOADING } from "@/core/services/store/user.module";
import { GET_ITEMS } from "@/core/services/store/REST.module";
import { LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import store from "@/core/services";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
//// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
require("vue-simple-calendar/static/css/default.css")
require("vue-simple-calendar/static/css/holidays-us.css")
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";

export default {
  name: "Ajadam",
  components: {
    CalendarView,
    CalendarViewHeader,
    DefaultLoading,
    MultipleSelectInput
  },
  mixins: [CalendarMathMixin],
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, user_module);
  },
  data() {
    return {
      displayPeriods: [
        {
          id: 'month',
          name: this.$t('ajadam.month')
        },
        {
          id: 'week',
          name: this.$t('ajadam.week')
        },
        {
          id: 'year',
          name: this.$t('ajadam.year')
        }
      ],
      contentTypes: [
        {
          id: 'all',
          name: this.$t('general.all')
        },
        {
          id: 'training',
          name: this.$t('education.catalog_education')
        },
        {
          id: 'assignment',
          name: this.$t('education.new_assigned_education')
        }
      ],
      /* Show the current month, and give it some fake items to show */
      showDate: this.thisMonth(1),
      message: "",
      startingDayOfWeek: 1,
      disablePast: false,
      disableFuture: false,
      displayPeriodUom: { id: 'month', name: this.$t('ajadam.month') },
      contentType: { id: 'all', name: this.$t('general.all') },
      displayPeriodCount: 1,
      displayWeekNumbers: false,
      showTimes: true,
      selectionStart: null,
      selectionEnd: null,
      newItemTitle: "",
      newItemStartDate: "",
      newItemEndDate: "",
      useDefaultTheme: true,
      useHolidayTheme: true,
      useTodayIcons: false,
      userBirthDay: 20,
      calendar_loading: false,
      items: [],
      apiURL: 'api/user/self',
      apiTrainingURL: 'api/agenda-training',
      apiAssignmentURL: 'api/assignment'
    }
  },
  computed: {
    userLocale() {
      return this.getDefaultBrowserLocale
    },
    current_user: {
      get() {
        let user = this.$store.getters[MODULE_NAME + "/" + CURRENT_ITEM];
        this.userBirthDay = user.dob ? moment(user.dob).format('DD') : '-'
        return user
      },
      set(val) { }
    },
    dayNames() {
      return this.getFormattedWeekdayNames(this.userLocale, "long", 0)
    },
    themeClasses() {
      return {
        "theme-default": this.useDefaultTheme,
        "holiday-us-traditional": this.useHolidayTheme,
        "holiday-us-official": this.useHolidayTheme,
      }
    },
    myDateClasses() {
      // This was added to demonstrate the dateClasses prop. Note in particular that the
      // keys of the object are `yyyy-mm-dd` ISO date strings (not dates), and the values
      // for those keys are strings or string arrays. Keep in mind that your CSS to style these
      // may need to be fairly specific to make it override your theme's styles. See the
      // CSS at the bottom of this component to see how these are styled.
      const o = {}
      const theFirst = this.thisMonth(1)
      const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13
      const idesDate = this.thisMonth(ides)
      o[this.isoYearMonthDay(idesDate)] = "ides"
      o[this.isoYearMonthDay(this.thisMonth(21))] = [
        "do-you-remember",
        "the-21st",
      ]
      return o
    },
  },
  methods: {
    onChange(event) {
      self = this
      self.items = []
      self.calendar_loading = true
      let temp = [];
      if (self.contentType.id == "training" && this.CatalogTrainingVisible) {
        this.$store.dispatch(GET_ITEMS, {
          url: this.apiTrainingURL,
          filters: { 'by_user': 1 },
          acceptPromise: false,
        }).then(result => {
          if (result.status) {
            temp = temp.concat(this.getResultFormattedArray(result.data.data, 'user.eduction-detail'))
          }
        }).then(() => {
          this.items = [...this.items, ...temp];
          self.calendar_loading = false
        })
      } else if (self.contentType.id == "assignment") {
        this.$store.dispatch(GET_ITEMS, {
          url: this.apiAssignmentURL,
          filters: { 'by_user': 1 },
          acceptPromise: false,
        }).then(result => {
          if (result.status) {
            temp = temp.concat(this.getResultFormattedArray(result.data, 'user.assignment-detail'))
          }
        }).then(() => {
          this.items = [...this.items, ...temp];
          self.calendar_loading = false
        })
      } else if (self.contentType.id == "all") {
        self.getAllItems()
      }
    },
    getCurrentUser() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_USER, {
        url: this.apiURL,
        acceptPromise: true,
      })
    },
    getAllItems() {
      let temp = [];

      if (!this.CatalogTrainingVisible) {
        this.$store.dispatch(GET_ITEMS, {
          url: this.apiAssignmentURL,
          filters: { 'by_user': 1 },
          acceptPromise: false,
        }).then(result => {
          if (result.status) {
            temp = temp.concat(this.getResultFormattedArray(result.data, 'user.assignment-detail'))
          }
        }).then(() => {
          this.items = [...this.items, ...temp];
          self.calendar_loading = false
        })
      } else {
        this.$store.dispatch(GET_ITEMS, {
          url: this.apiTrainingURL,
          filters: { 'by_user': 1 },
          acceptPromise: false,
        }).then(result => {
          if (result.status) {
            temp = temp.concat(this.getResultFormattedArray(result.data.data, 'user.eduction-detail'))
          }
        }).then(() => {
          if (!this.CatalogTrainingVisible) {
            return false;
          }
          this.$store.dispatch(GET_ITEMS, {
            url: this.apiAssignmentURL,
            filters: { 'by_user': 1 },
            acceptPromise: false,
          }).then(result => {
            if (result.status) {
              temp = temp.concat(this.getResultFormattedArray(result.data, 'user.assignment-detail'))
            }
          }).then(() => {
            this.items = [...this.items, ...temp];
            self.calendar_loading = false
          })
        })
      }
    },
    getResultFormattedArray(data, routeName) {
      let result = []
      for (let i = 0; i < data.length; i++) {
        let tempCurrent = data[i];
        let current = moment().format('YYYY-MM-DD');
        let endDate = moment(tempCurrent.assigned_users ? tempCurrent.assigned_users[0].pivot.created_at : tempCurrent.end_date);
        let diffAsDays = endDate.diff(current, 'days');


        let notificationIcon = "";
        if (diffAsDays > 0 && diffAsDays <= 7) {
          notificationIcon = '<span class="blinking-icon"></span>';
        }

        result.push({
          'id': tempCurrent.id,
          'route': routeName,
          'startDate': moment(tempCurrent.assigned_users ? tempCurrent.assigned_users[0].pivot.created_at : tempCurrent.start_date, 'YYYY-MM-DD').toDate(),
          'endDate': moment(tempCurrent.assigned_users ? tempCurrent.assigned_users[0].pivot.created_at : tempCurrent.end_date, 'YYYY-MM-DD').toDate(),
          'title': notificationIcon + tempCurrent.name,
        })
      }
      return result
    },
    periodChanged() {
      // range, eventSource) {
      // Demo does nothing with this information, just including the method to demonstrate how
      // you can listen for changes to the displayed range and react to them (by loading items, etc.)
    },
    thisMonth(d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    onClickDay(d) {
      this.selectionStart = null
      this.selectionEnd = null
      this.message = `You clicked: ${d.toLocaleDateString()}`
    },
    onClickItem(e) {
      this.$router.push({ name: e.originalItem.route, params: { id: e.id } })
    },
    setShowDate(d) {
      this.message = `Changing calendar view to ${d.toLocaleDateString()}`
      this.showDate = d
    },
    setSelection(dateRange) {
      this.selectionEnd = dateRange[1]
      this.selectionStart = dateRange[0]
    },
    finishSelection(dateRange) {
      this.setSelection(dateRange)
      this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`
    },
    onDrop(item, date) {
      this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`
      // Determine the delta between the old start date and the date chosen,
      // and apply that delta to both the start and end date to move the item.
      const eLength = this.dayDiff(item.startDate, date)
      item.originalItem.startDate = this.addDays(item.startDate, eLength)
      item.originalItem.endDate = this.addDays(item.endDate, eLength)
    },
    clickTestAddItem() {
      this.items.push({
        startDate: this.newItemStartDate,
        endDate: this.newItemEndDate,
        title: this.newItemTitle,
        id: "e" + Math.random().toString(36).substr(2, 10),
      })
      this.message = "You added a calendar item!"
    },
  },
  mounted() {
    let self = this;
    self.newItemStartDate = self.isoYearMonthDay(self.today())
    self.newItemEndDate = self.isoYearMonthDay(self.today())
    self.getCurrentUser();
    self.getAllItems();
    $('.filter_selectpicker').selectpicker()
  },

}
</script>
<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  background-color: #f7fcff;
}

#app {
  display: flex;
  flex-direction: row;
  font-family: Calibri, sans-serif;
  width: 95vw;
  min-width: 30rem;
  max-width: 100rem;
  min-height: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.calendar-controls {
  margin-right: 1rem;
  min-width: 14rem;
  max-width: 14rem;
}

.calendar-parent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 80vh;
  background-color: white;
}

/* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
.cv-wrapper.period-month.periodCount-2 .cv-week,
.cv-wrapper.period-month.periodCount-3 .cv-week,
.cv-wrapper.period-year .cv-week {
  min-height: 6rem;
}

/* These styles are optional, to illustrate the flexbility of styling the calendar purely with CSS. */
/* Add some styling for items tagged with the "birthday" class */
.theme-default .cv-item.birthday {
  background-color: #e0f0e0;
  border-color: #d7e7d7;
}

.theme-default .cv-item.birthday::before {
  content: "\1F382";
  /* Birthday cake */
  margin-right: 0.5em;
}

.box {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin: 5px;

}

.checkboxClass {
  display: inline-block;
  font-size: 1em !important;

}

input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(01);
  /* IE */
  -moz-transform: scale(1);
  /* FF */
  -webkit-transform: scale(1);
  /* Safari and Chrome */
  -o-transform: scale(1);
  /* Opera */
  transform: scale(1);
  display: inline-block;
  width: 25px;
  height: auto;

}

.field label.checkbox {
  font-size: 1rem;
  font-weight: 300 !important;
  line-height: 1.1rem;
}

select.btn-mini {
  line-height: 14px;
  width: auto;
}

.checkbox-wrapper {
  white-space: nowrap
}

.checkbox {
  vertical-align: top;
  display: inline-block
}

.checkbox-label {
  white-space: normal;
  display: inline-block
}

.calendar_h {
  height: 600px !important;
}


.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
